import React from "react"
import '../../styles/aboutOverlay.scss';

const AboutOverlay = () => {
  return (
    <div className="about-intro-overlay">
      <div className="about-front-1"></div>
{      <div className="about-middle-1"></div>}
      <div className="about-back-1"></div>
   
    </div>
  )
}

export default AboutOverlay
