import React,{useEffect, useRef} from 'react'
import '../styles/contactPageStyles.scss'
import {Link, navigate} from 'gatsby'
import {ArrowTop} from '../assets/social-icons'
import {gsap} from 'gsap'
import AboutOverlay from '../components/Overlays/AboutOverlay'
import {
    useGlobalDispatchContext,
  } from '../context/globalContext'


const Contact = () => {


    const dispatch = useGlobalDispatchContext()

  
    const onCursor = cursorType => {
        dispatch({ type: "CURSOR_TYPE", cursorType: cursorType })
    }




    const container = useRef(null)
    const info = useRef(null)
    const contactList = useRef(null)
    const social = useRef(null)
    const navigation = useRef(null)  


   
  

    useEffect(() => {

        
         //timeline
        const tl = gsap.timeline()
      tl.fromTo( '.about-back-1',{
          yPercent:-150
      },{
          yPercent:100,
          ease: "Power2.easeOut",
          stagger: 0.5,
          delay:  .2,
          duration:  2
      })
       .fromTo('.about-middle-1',{
          yPercent:-100,
      },{
            yPercent:100,
            ease:'Power2.easeOut',
            duration:  0,
            delay: -.6
      }) 
      .fromTo('.about-front-1',{
        yPercent:-100
        },{
          yPercent:100,
          ease:'Power2.easeOut',
          duration:  0,
          delay: -.6
        })
        .to(['.about-front-1','.about-middle-1', '.about-back-1'], {
            css:{
                display:'none'
            }
        })
         .from('.contact-left-column p', {
             duration:1.2,
             delay: -0.7,
             skewY:10,
             y:120,
             stagger:{
                 amount:.4
             },
             ease: "Power3.easeOut",
             opacity:0
         })
         .from('.redirect',{
             duration: .5,
             opacity:0,
         })
         .from('.contact p',{
             duration: .7,
             delay:-1,
             skewY:10,
             y:110,
             ease:"Power3.easeOut",
             stagger:{
                 amount:.2
             },
             opacity:0
         })
         .from('.contact-social-list li',{
             duration:1,
             y:40,
             delay:-.7,
             stagger:{
                 amount: .4
             },
             ease:"Power3.easeOut",
             opacity:0
         })     
      })
    
    

      const handleClick = (e) => {
        e.preventDefault()
         const tl = gsap.timeline()
         tl.to([info.current, social.current], {
             duration:.8,
             y:-40,
             opacity:0,
             ease:"Power3.easeOut",
             stagger:{
                 amount:.3
             }
         })
         .to(navigation.current,{
             duration:.4,
             opacity:0,
             y:-20,
             ease:"Power3.easeOut"
         })

          setTimeout(() => {
              navigate('/')
          }, 1800);
      }



    return (
        <>
        <AboutOverlay/> 
        <div className='contact-container' ref={container}>
            <div className="contact-wrapper"  >
                <div className="redirect" ref={navigation} >
                    <div className='back-home'>
                        <ArrowTop/>
                        <Link to='/' onClick={handleClick} onMouseEnter={()=>onCursor('pointer')} onMouseLeave={()=>onCursor('')}>Back to home</Link>
                    </div>
                    <p><span>03</span> - Contact</p>
                </div>
               
                <div className="contact-left-column" ref={info}>
                    <div className="line-overlay">
                        <p className='headline'>Let's work together<span>.</span></p>
                    </div>
                </div>
                <div className="contact-right-column" ref={social}>
                    <div className="contact">
                        <div className="line-overlay">
                            <p>
                                <a href="mailto:tzamalisn@gmx.de" onMouseLeave={()=>onCursor('')} onMouseEnter={()=>onCursor('pointer')}>E-mail: tzamalisn@gmx.de</a>
                            </p>
                        </div>
                        <div className="line-overlay">
                            <p>Mobile: (+30) 6973067764</p>  
                        </div>
                    </div>
                    <div className="contact-social">
                        <ul className="contact-social-list" ref={contactList}>
                            <li>
                                <a onMouseEnter={()=>onCursor('pointer')} onMouseLeave={()=>onCursor('')}  href="#">Facebook</a>
                            </li>
                            <li>
                                <a onMouseEnter={()=>onCursor('pointer')} onMouseLeave={()=>onCursor('')}  href="#">Twitter</a>
                            </li>
                            <li>
                                <a onMouseEnter={()=>onCursor('pointer')} onMouseLeave={()=>onCursor('')}  href="#">LinkedIn</a>
                            </li>
                            <li> 
                                <a onMouseEnter={()=>onCursor('pointer')} onMouseLeave={()=>onCursor('')}  href="#">Github</a>
                            </li>
                        </ul>
                    </div>
                   
                </div>
            </div>
        </div>
        </>
    )
}

export default Contact
